<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="card card-custom card-stretch">
                    <div class="card-body pt-4">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <div class="col-md-6">
                                <v-text-field
                                    v-model="name"
                                    :rules="name_rules"
                                    label="Tên"
                                    required
                                ></v-text-field>
                            </div>

                            <div class="col-md-6">
                                <v-text-field
                                    v-model="description"
                                    label="Mô tả"
                                    required
                                ></v-text-field>
                            </div>

                            <div class="col-md-6">
                                <v-text-field
                                    v-model="status"
                                    label="Trạng thái"
                                    required
                                ></v-text-field>
                            </div>

                            <div class="col-md-6">
                                <v-select
                                    v-model="parent_cate_id"
                                    :items="categories"
                                    label="Parent Category"
                                    item-text="name"
                                    item-value="id"
                                ></v-select>
                            </div>

                            <div class="col-md-6">
                                <v-text-field
                                    v-model="thumbnail"
                                    label="Hình ảnh"
                                    required
                                ></v-text-field>
                            </div>

                            
                            <div class="col-md-6">
                                <v-btn
                                    class="ma-2 float-right"
                                    :loading="loading"
                                    color="info"
                                    @click="create_caterory"
                                >
                                    Thêm
                                </v-btn>

                                <v-btn
                                    class="ma-2 float-right"
                                    :loading="loading"
                                    @click="cancel"
                                >
                                    Huỷ
                                </v-btn>
                            </div>

                        </v-form>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    metaInfo: {
        title: 'Users'
    },
    data() {
        return {
            valid: true,
            name: "",
            description: "",
            thumbnail: "",
            status: null,
            parent_cate_id: null,
            loading: false,
            name_rules: [],
        };
    },
    watch: {
        
    },
    computed: {
        categories() {
            return this.$store.getters['categories/get_categories'];
        },
    },
    components: {
        
    },
    methods: {
        create_caterory() {
            this.loading = true;
            var payload = {
                name: this.name,
                description: this.description,
                thumbnail: this.thumbnail,
                parent_cate_id: this.parent_cate_id,
                status: parseInt(this.status),
                toast: this.$root.$bvToast,
            }
            this.$store.dispatch('categories/create_category', payload).then(() => {
                this.loading = false;
                this.$router.go(-1).catch(() => { });
            }).catch(() => {
                this.loading = false;
            });
        },
        cancel() {
            this.$router.go(-1);
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Category", route: "index" }, { title: "Thêm category" } ]);
        this.$store.dispatch('categories/get_all');
    }
};
</script>



